:root {
    --white: #ffffff;
    --black: rgba(6,8,4,1);

    --primary: var(--black);
    --secondary: var(--white);

    --grey: #C0C8CF;
    --yellow: #FFF02F;
    --blue: #2FB7FF;
    --dark: #6E6956;


    --assertive: #ff00ff;
    --focus: #ff00ff;
    --color-error: #ff0000;
    --color-sucess: #4dc247;
}