.post {
    padding: var(--header-height) var(--padding-m);
    display: flex;
    
    > * {
        width: 50%;
    }
    
    @media (max-width: $smartphone) {
        display: block;

        > * {
            width: 100%;
        }
        
    }

    > .deco {
        --width: calc(100vw - var(--padding-m) * 2);

        position: absolute;
        width: var(--width);
        height: auto;
        top: calc(-.35 * var(--width));
        left: var(--padding-m);
        
        polygon {
            fill: var(--blue);
        }
    }

    > h1 {
        position: relative;
        @include font-mono();
        font-size: var(--font-size-xxl);
        text-transform: uppercase;
        height: calc(100vh - var(--header-height) - var(--footer-height) - var(--grid));
        padding: var(--grid) var(--padding-xs);

        @media (max-width: $smartphone) {
            height: auto;
            padding: 0;
        }
    }
}
