@use "sass:math";

#Footer {
    --font-size: var(--font-size-base);
    --padding-v: 0;
    --padding-h: var(--padding-m);
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xxsmall);
        --padding-v: 0;
        --padding-h: var(--padding-m);
    }
}

#Footer {
    display: grid;
    grid-template-columns: repeat(27, var(--grid));
    grid-template-rows: repeat(5, var(--grid));
    grid-template-areas:"a a a a a a a a a 0 0 d d d d d 1 t t t t t t t t t t"
                        "a a a a a a a a a 0 0 l l l l l l l l l l l l l l l l"
                        "2 2 2 2 2 2 2 2 2 2 2 2 2 2 2 2 2 2 2 2 2 2 2 2 2 2 2"
                        "b b b b 3 m m m m m m m 4 f f f f f f f f f f f f f f"
                        "c c c c c c c c c c c c c c c c c c c c c c c c c c c";
    width: 100%;
    height: var(--footer-height);
    padding: var(--padding-v) var(--padding-h);

    @include font-mono();
    font-size: var(--font-size);
    text-transform: uppercase;

    @media (max-width: $smartphone) {
        display: block;
    }
}

.footer__logo {
    position: relative;
    grid-area: a;

    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }
}

.footer__date {
    grid-area: d;
    background-color: var(--blue);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: .1em .2em;
    color: var(--yellow);

    @media (max-width: $smartphone) {
        margin-top: var(--grid);
        justify-content: flex-start;
    }
}

.footer__title {
    grid-area: t;
    background-color: var(--blue);
    display: flex;
    align-items: flex-end;
    padding: .1em .2em;
    color: var(--yellow);

    @media (max-width: $smartphone) {
        margin-top: var(--grid);
        justify-content: flex-start;
    }
}

.footer__nav {
    grid-area: l;
}

.footer__rrss {
    grid-area: r;
}

.footer__links {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;      

    a {
        display: block;
        flex: 0 0 calc(var(--grid) * 2);
        width: calc(var(--grid) * 2);

        @include basic-a();
        padding: .1em .2em;
        background-color: var(--dark);
        color: var(--yellow);

        &:not(:last-child) {
            margin-right: var(--grid);
        }

        @media (max-width: $smartphone) {
            flex: 0 0 auto;
            width: auto;
            margin-top: var(--grid);
        }
    }
}

.footer__by {
    grid-area: b;
    background-color: var(--yellow);
}

.footer__money {
    grid-area: m;
    background-color: var(--yellow);
}

.footer__friends {
    grid-area: f;
    background-color: var(--yellow);
}

.footer__logos {
    display: flex;
    align-items: flex-end;
    color: var(--dark);

   

    > .title {
        flex: 0 0 calc(var(--grid) * 2);
        display: block;
        padding: .1em .2em;
    }

    > .image {
        position: relative;
        flex: 1 0 auto;
        height: 100%;

        figure {
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;

            > img {
                width: 100%;
                height: 100%;
            }
        }

        
    }
}

.footer__legales {
    grid-area: c;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-s);

    a {
        @include basic-a();
        color: var(--color);
        margin: 0 1em;

        
    }
}