@use "sass:math";

:root {
  --y-header: 0;
  --columns: 29;
  --grid: calc(100vw/var(--columns));

  --padding-xl: calc(var(--grid)/2);
  --padding-l: calc(var(--grid)/1.5);
  --padding-m: var(--grid);
  --padding-s: calc(var(--grid)/2);
  --padding-xs: calc(var(--grid)/3);
  --padding-xxs: calc(var(--grid)/4);
  --padding-xxxs: calc(var(--grid)/5);
  
  --header-height: var(--grid);
  --footer-height: calc(var(--grid) * 5);
}

@media (max-width: $smartphone) {
  :root {
    --columns: 10;
  }
}
