@use "sass:math";

@media (max-width: $smartphone) {

    #Interface__Canvas {
        opacity: .2;
    }

#Footer {
    --font-size: var(--font-size-xxsmall);
    --padding-v: 0;
    --padding-h: var(--padding-m);
}

#Footer {
    display: block;
    height: auto;
}

.footer__logo {
    position: relative;
    width: 100%;

    svg {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        object-fit: contain;
        object-position: center;
    }
}

.footer__date {
    margin-top: 10px;
}

.footer__title {
    margin-top: 10px;
}

.footer__nav {
    grid-area: l;
}

.footer__rrss {
    grid-area: r;
}

.footer__links {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;      

    a {
        display: block;
        flex: 0 0 auto;
        width: auto;

        &:not(:last-child) {
            margin-right: 10px;
        }

        @media (max-width: $smartphone) {
            margin-top: 10px;
        }
    }
}

.footer__by {
    width: 100%;
    margin-top: 10px;
    background-color: var(--yellow);
}

.footer__money {
    width: 100%;
    margin-top: 10px;
    background-color: var(--yellow);
}

.footer__friends {
    width: 100%;
    margin-top: 10px;
    background-color: var(--yellow);
}

.footer__logos {
    display: block;
    color: var(--dark);

   

    > .title {
        width: 100%;
        display: block;
        padding: .1em .2em;
    }

    > .image {
        position: relative;
        flex: 1 0 100%;
        width: 100%;
        height: 60px;

        figure {
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;

            > img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: left;
            }
        }

        
    }
}

.footer__legales {
    grid-area: c;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-s);

    a {
        margin: 10px 0;
        text-align: center;
    }
}

}