#Sidemenu {
    --width: calc(var(--grid) * 8);
    --max-height: 100vh;
    --bg: var(--yellow);
    --color: var(--dark);
    --font-size: var(--font-size-base);
    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-xs);
    --left: var(--padding-m);
    
    @media (max-width: $smartphone) {
        --padding-v: calc(var(--padding-xl) * 2);
        --left: var(--padding-xs);
        --width: calc(100vw - var(--padding-xs) * 2);
        --max-height: calc((var(--vh, 1vh) * 100) - var(--header-height));
    }
}

#Sidemenu {
    @include z-index($z-index-sidemenu);
    position: fixed;
    top: var(--header-height);
    left: var(--left);    
    width: var(--width);
    height: auto;
    max-height: var(--max-height);
    
    background-color: var(--bg);
    color: var(--color);
    font-size: var(--font-size);

    .__scroll-manual & {
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
    }

    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
    }

    overflow: hidden;

    > div {
        padding: var(--padding-v)  var(--padding-h) calc(var(--padding-v) * 2);
    }

    ul {
        list-style: none;
        padding: 0;
    }

    a {
        @include basic-a();
        @include font-mono();

        font-size: var(--font-size);
        color: var(--color);
        text-transform: uppercase;

        > .id {
            position: absolute;
            display: inline-block;
            top: 0;
            right: var(--padding-h);
            padding: .6em 0;
            opacity: 0;
        }

        > .text {
            position: relative;
            display: block;
            padding: .6em var(--padding-xs);
            margin-bottom: .2em;

            &::before {
                position: absolute;
                top:0;
                left:0;
                content: '';
                width: #{rem(4)};
                height: 100%;
                background-color: var(--grey);
            }

            &::after {
                float: right;
                display: inline;
                opacity: 0;
                content: "««««««««««««";
            }
        }

        &:hover {
            > .text {
                &::before {
                    background-color: var(--blue);
                }
                &::after {
                    opacity: 1;
                }
            }
        }
    }

    ul.nav {
        display: none;
        list-style: none;
        margin-bottom: 2em;

        @media (max-width: $smartphone) {
            display: block;
        }

        li {
            display: block;
        }

        a {
            @include basic-a();
            padding: .6em var(--padding-xs) .6em 0;

            &.is-active {
                display: none;
            }

            &::after {
                content: '↘';
            }
        }
    }
}