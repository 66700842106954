@use "sass:math";

#Header {
    --width: 100%;
    --height: var(--header-height);
    --color: var(--primary-color);
    --bg: transparent;//var(--primary-bg);
    --padding-v: 0;
    --padding-h: var(--padding-m);
    --font-size: var(--font-size-xl);


}

#Header {
    @include z-index($z-index-header);
    position: fixed;
    width: var(--width);
    height: var(--height);
    right: 0;
    top: 0;
    padding: var(--padding-v) var(--padding-h);

    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--bg);

    @include font-mono(1);
    font-size: var(--font-size);
    color: var(--color);
    text-transform: uppercase;
   

    button,
    a {
        @include basic-a();
        height: var(--header);
        background-color: var(--secondary-color);
        border: 2px solid var(--secondary-color);
        color: inherit;
        display: flex;
        align-items: center;
        padding: 0 var(--padding-xs);
    }

    @media (max-width: $smartphone) {
        a {
            display: none;
        }
    }

    .coords {
        position: relative;
        height: 100%;
        flex: 1 0 auto;
        white-space: nowrap;
        overflow: hidden;
        text-align: right;

        > div {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}

#Header > nav {
    display: flex;
    height: 100%;

    button,
    a {
        width: auto;
        text-transform: uppercase;
        
        &:after {
            content: '↘';
        }

        &.--download {
            &:after {
                content: '↓';
            }   
        }

        &.--close {
            &:after {
                content: '↑';
            }   
        }

        &.is-active {
            display: none;
        }
        &[aria-current=page] {
            display: none;
        }

        &:hover {
            background-color: transparent;
            color: var(--secondary-color);
        }
    }
}

#Header .toggle {
    .mobile {
        display: none;

        @media (max-width: $smartphone) {
            display: block;
        }
    }
}

@media (max-width: $smartphone) {
  #Header {
      --font-size: #{math.div(16px, 16px) * 1rem};

      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;

      > .title {
          grid-column: 1 / 2;
          grid-row: 1 / 2;
      }

      > .date {
          grid-column: 1 / 2;
          grid-row: 2 / 3;

          align-self: flex-end;
      }
  }

  #Header > nav {
      grid-column: 2 / 3;
      grid-row: 1 / 3;
      grid-template-columns: repeat(1, 1fr);

      text-align: right;

      a:not(:last-of-type) {
          margin-bottom: .4em;
      }
  }

    #Header .toggle .desktop {
        display: none;
    }
}
