.form {
    --font-size: var(--font-size-xxsmall);
    --input-height: calc(var(--font-size) * 1.5);
}

.form {
    font-size: var(--font-size);
    margin-left: auto;
    margin-right: auto;
    max-width: rem(900);

    input,
    label,
    textarea,
    select,
    fieldset {
        background-color: transparent;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        padding: 0;
        outline: none;
        margin: 0;
        -webkit-appearance: none;
    }

    textarea {
        height: 100%;
        resize: none;
        width: 100%;
    }

    select,
    input {
        background: transparent;
        color: var(--white);
        cursor: pointer;

        &:not([type='checkbox']) {
            height: var(--input-height);
            line-height: var(--input-height);
            width: 100%;
        }
    }

    label {
        color: var(--white);
        left: 0;
        line-height: var(--input-height);
        position: absolute;
        pointer-events: none;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        transform-origin: center left;
        transition: 0.2s ease-in-out;
    }

    &__wrapper:not(:last-child) {
        margin-bottom: var(--padding-m);
    }

    fieldset {
        border-bottom: 1px solid currentColor;
        height: calc(rem(12) + var(--input-height));
        padding: rem(6) 0;
        position: relative;

        &:not(:last-child) {
            margin-bottom: var(--padding-m);
        }
        
        &.--textarea {
            height: calc(rem(12) + 6 * var(--input-height));

            label {
                top: var(--input-height) / 2;
            }
        }

        &.--select {
            &::before {
                color: currentColor;
                content: '+';
                height: 100%;
                line-height: 180%;
                position: absolute;
                pointer-events: none;
                right: 0;
                top: 0;
                transform: scale(1.5);
            }
        }

        &.--file,
        &.--checkbox {
            border: 0;

            &.--focus,
            &.--success,
            &.--error {
                label {
                    transform: none;
                    top: 0;
                }
            }

            &.--success label {
                border-color: var(--color-success);
            }
            
            &.--error label {
                border-color: var(--color-error);
            }

            label {
                cursor: pointer;
                pointer-events: initial;
                position: static;
                transform: none;
                top: 0;
            }
        }

        &.--file {
            align-items: center;
            border: 0;
            display: flex;
            margin-bottom: 0;

            &:last-child {
                margin-bottom: var(--padding-s);
            } 
            
            label {
                border-bottom: 1px solid var(--white);
            }

            input {
                display: none;
            }

            .remove {
                background-color: transparent;
                height: rem(16);
                margin-left: var(--padding-xs);
                padding: 0;
                width: rem(16);
                
                svg {
                    height: 100%;
                    width: 100%;
                    transform: rotate(45deg);
                }
            }

            // &.--success label {
            //     border-color: var(--color-success);
            // }
            
            // &.--error label {
            //     border-color: var(--color-error);
            // }
        }

        &.--checkbox {
            margin: 0;
            padding: 0;

            // &.--focus,
            // &.--success,
            // &.--error {
            //     label {
            //         color: var(--gray-300);
            //         transform: none;
            //         top: 0;
            //     }
            // }
        }

        &.--success {
            border-color: var(--color-success);
        }

        &.--error {
            border-color: var(--color-error);
        }

        &.--focus,
        &.--success,
        &.--error {
            label {
                transform: scale3d(0.78, 0.78, 1) translate3d(0, -50%, 0);
                top: -25%;
            }
        }
    }

    ::placeholder,
    :-ms-input-placeholder,
    ::-ms-input-placeholder {
        color: var(--gray);
    }

    .hidden {
        display: none;
    }

    .button {
        --padding-h: var(--padding-l);

        &[disabled] {
            opacity: .5;
            pointer-events: none;
        }
    }

    @media (max-width: $smartphone) {
        &__footer {
            margin-top: var(--padding-l);

            .button {
                width: 100%;

                &:first-child {
                    margin-bottom: var(--padding-xs);
                }
            }
        }
    }

    @media (min-width: $smartphone) {
        &__footer {
            display: flex;
            justify-content: flex-end;
            margin-top: var(--padding-m);

            .button {
                margin-left: var(--padding-m);
            }
        }
    }
}
