@use "sass:math";

:root {
    --font-mono: 'SpaceMono-Regular', sans-serif;
    --font-sans: 'NeueMontreal-Regular', sans-serif;
    --font-sans-bold: 'NeueMontreal-Regular', sans-serif;
    
    --font-size-xxl: #{math.div(52px, 16px) * 1rem};
    --font-size-xl: #{math.div(26px, 16px) * 1rem};
    --font-size-l: #{math.div(20px, 16px) * 1rem};
    --font-size-base: #{math.div(16px, 16px) * 1rem};
    --font-size-s: #{math.div(10px, 16px) * 1rem};
    
    --line-height-sans: 1.2;
    --letter-spacing: 0em;

    --min-font-size: 6px;
    --max-font-size: 98px;
    --font-size: clamp(var(--min-font-size), 1.1111vw * var(--zoom), var(--max-font-size));
    font-size: var(--font-size);
    
    @media (max-width: $smartphone) {
        --font-size: 16px;
    }
}

@mixin font-sans($line-height:0) {
    font-family: var(--font-sans);
    font-weight: normal;
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}
@mixin font-sans-italic($line-height:0) {
    @include font-sans($line-height);
    font-style: italic;
}
@mixin font-sans-bold($line-height:0) {
    @include font-sans($line-height);
    font-weight: 700;
}
@mixin font-mono($line-height:0) {
    font-family: var(--font-mono);
    font-weight: normal;
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}
