.default {
    position: relative;
}

.widget-home {
    --font-size: 2.5vw;
    --width: 60%;

    @media (max-width: $smartphone) {
        --font-size: 20px;
        --width: 100%;
    }
}

.widget-home {
    position: absolute;
    width: 100%;
    height: calc(100% - var(--grid));

    @media (max-width: $smartphone) {
        position: relative;
        height: 60vh;
    }
}
.widget-home ul {
    opacity: 0;
    pointer-events: none;
}

.widget-home > svg {
    position: absolute;
    width: calc(100% - var(--grid) * 2);
    height: calc(100% - var(--grid));
    opacity: 1;
    top:var(--grid);
    left:var(--grid);
   
    path,
    polygon {
    fill: var(--blue);
    }
}

.widget-home > .title {
    position: relative;
    @include font-mono();
    color: var(--yellow);
    font-size: var(--font-size);
    padding: var(--grid);
    text-transform: uppercase;
    width: var(--width);

    > span {
        background-image: linear-gradient(var(--dark), var(--dark));
        background-position: 0% 60%;
        background-repeat: no-repeat;
        background-size: 100% 70%;
    }
}